.hero {

	.section-inner {
		padding-top: $hero--padding-t__mobile;
		padding-bottom: $hero--padding-b__mobile;
	}
}

.hero-inner {
	.title{
		font-size: 34px;
		@media (min-width: 900px) {
			font-size: 40px;
		}
	}
	.left-container{
		padding-left: 16px !important;
		@media (min-width: 900px) {
			padding-left: 40px !important;
		}
	}
	.hero-video{
		position: relative;
		img{
			position: absolute;
			width: 150px;
			height: auto;
			top: 20px;
			left: -20px;
			border-radius: 10px;
		}
		video{

			height: 100%;
			width: 100%;
			@media (min-width: 900px) {
				height: 400px;
				width: 100%;
				object-fit: cover;
			}
		}
	}

	// Affects only hero full
	> .hero-content + .hero-figure,
	> .hero-figure + .hero-content {
		margin-top: $hero--inner-padding-v_mobile;
	}

	> .hero-figure {

		> a {
			display: inline-flex;
			// prevents stretching
			align-items: center;
			vertical-align: top;
		}
	}
}

@include media( '<=medium' ) {

	.hero {

		.split-wrap {

			.split-item {

				.split-item-content {
					margin-bottom: $hero--inner-padding-v_mobile;
				}
			}

			&.invert-mobile {

				.split-item {

					.split-item-image {
						margin-bottom: $hero--inner-padding-v_mobile;
					}
				}
			}
		}
	}
}

@include media( '>medium' ) {

	.hero {

		.section-inner {
			padding-top: $hero--padding-t__desktop;
			padding-bottom: $hero--padding-b__desktop;
		}
	}

	.hero-inner {

		// Affects only hero full
		> .hero-content + .hero-figure,
		> .hero-figure + .hero-content {
			margin-top: $hero--inner-padding-v_desktop;
		}
	}
}

.bone{
	overflow-x: hidden;
	@media (max-width: 1100.5px) {

		.MuiGrid-root {
			width: 100%;
			margin-left: 0px;
			img{
				border-radius: 0px;
			}
		}
	}
}